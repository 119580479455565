var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(" School Years / Sessions "),
                      _c("v-spacer"),
                      _vm.canEditSettings
                        ? _c(
                            "v-btn",
                            {
                              attrs: { fab: "", small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.createItem()
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-plus")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    { attrs: { shaped: "" } },
                    [
                      _c(
                        "v-list-item-group",
                        { attrs: { color: "primary" } },
                        _vm._l(_vm.semesters, function (semester, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function ($event) {
                                  return _vm.editItem(semester)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.colors[semester.color].color,
                                      },
                                    },
                                    [_vm._v("mdi-checkbox-blank-circle")]
                                  ),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(semester.name)),
                              ]),
                              semester.synced
                                ? _c("v-icon", [_vm._v("mdi-sync")])
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "9" } },
            [
              _vm.showable
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-space-between mb-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              _vm.editedItem = {}
                              _vm.showable = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "div",
                        [
                          _vm.canEditSettings && !_vm.isRoutingClient
                            ? _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.editedItem.locked &&
                                        _vm.editedItem.name,
                                      expression:
                                        "!editedItem.locked && editedItem.name",
                                    },
                                  ],
                                  staticClass: "mx-2",
                                  attrs: { dark: "", color: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveItem()
                                    },
                                  },
                                },
                                [_vm._v(" Save ")]
                              )
                            : _vm._e(),
                          _vm.canEditSettings && !_vm.isRoutingClient
                            ? _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.editedItem.locked &&
                                        _vm.editedItem.id,
                                      expression:
                                        "!editedItem.locked && editedItem.id",
                                    },
                                  ],
                                  staticClass: "mx-2",
                                  attrs: { dark: "", color: "error" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem()
                                    },
                                  },
                                },
                                [_vm._v(" Delete ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showable,
                      expression: "showable",
                    },
                  ],
                  staticClass: "mb-4",
                },
                [
                  _c(
                    "v-form",
                    {
                      attrs: {
                        readonly: !_vm.canEditSettings || _vm.editedItem.synced,
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            [
                              _vm.editedItem.synced
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        outlined: "",
                                        type: "info",
                                        text: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " This Semester / School Year is synced with EZRouting. Any changes will need to be made there to be synced here. "
                                      ),
                                    ]
                                  )
                                : !_vm.editedItem.id && _vm.isRoutingClient
                                ? _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        outlined: "",
                                        type: "info",
                                        text: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " This Semester / School Year will only be avilable in EZActivityTrips. If you would like this Semester / School Year to be available in EZRouting, please add it there first and it will be synced with EZActivityTrips. "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "School Year / Session Name",
                                        },
                                        model: {
                                          value: _vm.editedItem.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.years,
                                          "item-text": "label",
                                          "item-value": "id",
                                          label: "School Year",
                                          outlined: "",
                                        },
                                        model: {
                                          value: _vm.editedItem.schoolYear,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "schoolYear",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.schoolYear",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("date-picker", {
                                        attrs: {
                                          label: "Starting Date",
                                          required: "",
                                          readonly:
                                            !_vm.canEditSettings ||
                                            _vm.editedItem.synced,
                                        },
                                        model: {
                                          value: _vm.editedItem.fromDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "fromDate",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.fromDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("date-picker", {
                                        attrs: {
                                          label: "Ending Date",
                                          required: "",
                                          readonly:
                                            !_vm.canEditSettings ||
                                            _vm.editedItem.synced,
                                        },
                                        model: {
                                          value: _vm.editedItem.toDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "toDate",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.toDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.editedItem.id
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.colors,
                                              "item-text": "label",
                                              "item-value": "index",
                                              label: "Colors",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.editedItem.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression: "editedItem.color",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }